<template>
  <div class="nav_bar">
    <div class="nav_bars bgUrl" :class="currentBgClass" ref="bgUrlContainer">
      <div class="flex_between view_menu">
        <img class="logo_img" :src="logo_small" width="120px" @click="goIndex" />
        <div class="nav_body">
          <div
            class="nav_item"
            v-for="(item, index) in navList"
            :key="item.id"
            @mouseleave.native="resetBackground"
          >
            <router-link
              class="nav_name"
              :to="item.pathName"
              @mouseenter.native="changeBackground(item)"
            >
              {{ item.titleName }}
            </router-link>
          </div>
        </div>
        <img class="bottom_rihgt" :src="bottom_rihgt" width="120px" />
        <img class="lange" :src="lange" width="120px" @click="hanldeLange" />
      </div>
      <BottomInfo />
    </div>
  </div>
</template>
<script>
import logo_small from "@/assets/logo_small.png";
import bottom_rihgt from "@/assets/bottom_rihgt.png";
import lange from "@/assets/lange.png";
import { listProductCategory } from "@/api/activity";
import { getCompany } from "@/api/customer/outCustomer";
export default {
  data() {
    return {
      navList: [
        {
          id: 1,
          titleName: "PHILOSOPHY",
          pathName: "/PHILOSOPHY",
          bgImage: "../assets/imgs/0005.png",
        },
        {
          id: 2,
          titleName: "WORKS",
          pathName: "WORKS",
          bgImage: "../assets/imgs/0010.png",
        },
        {
          id: 3,
          titleName: "AWARDS",
          pathName: "AWARDS",
          bgImage: "../assets/imgs/0016.png",
        },
        {
          id: 4,
          titleName: "TEAM",
          pathName: "TEAM",
          bgImage: "../assets/imgs/0020.png",
        },
        {
          id: 5,
          titleName: "CONTACT",
          pathName: "CONTACT",
          bgImage: "../assets/imgs/0025.png",
        },
      ],
      logo_small,
      lange,
      bottom_rihgt,
      isRouter: false,
      currentBgImage: "",
      currentBgClass: "bg-con",
    };
  },
  created() {},
  methods: {
    navigator(item, index) {
      this.$router.push({
        name: item.pathName,
        // query: { categoryId: item.categoryId },
      });
    },
    changeBackground(item) {
      console.log("bgImage", item);
      // 设置当前背景类
      this.currentBgClass = `bg-${item.titleName.toLowerCase().replace(/\s+/g, "")}`;
      console.log("Changed to:", this.currentBgClass);
    },
    resetBackground() {
      // 当鼠标离开时重置背景类
      this.currentBgClass = "bg-con";
      console.log("Reset background");
    },
    // 语言
    hanldeLange() {
      const lange = localStorage.getItem("lange");
      if (lange==2) {
        localStorage.setItem("lange", 1);
        this.$message({
          message: '已设置英文',
          type: 'success',
          duration:1500
        });
      } else {
        localStorage.setItem("lange", 2);
        this.$message({
          message: '已设置中文',
          type: 'success',
          duration:1500
        });
      }
    },
    // 返回首页
    goIndex() {
      // window.location.reload(); // 强制刷新页面
      this.$router.push("/");
    },
  },
};
</script>
<style lang="scss" scoped>
body {
  margin: 0;
  padding: 0;
}
.container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.nav_name {
  // transform: translate(-50%, -50%);
  color: #cbcdd2;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  // text-decoration: none;
  // background-size: cover;
  // background-position: center;
  // transition: background-image 0.5s ease;
  transition: all 0.9s ease;
}
/* 背景层样式 */
.bg-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 0.5s ease;
  z-index: -1;
}
/* 应用动画 */
.nav_name:hover {
  color: #000000;
  // animation-name: changeBackground;
  // animation-duration: 3s; /* 动画持续时间 */
  // animation-iteration-count: infinite; /* 无限循环 */
  // animation-timing-function: ease-in-out; /* 动画速度曲线 */
}
//   /* 定义动画 */
//   @keyframes changeBackground {
//   0% { background-image: url('../assets/imgs/0005.png'); }
//   33% { background-image: url('../assets/imgs/0010.png'); }
//   66% { background-image:url('../assets/imgs/0016.png'); }
//   100% { background-image: url('../assets/imgs/0020.png'); }
// }
/* 定义不同背景图片对应的类 */
.bg-philosophy {
  background-image: url("../assets/imgs/0005.png");
}
.bg-works {
  background-image: url("../assets/imgs/0010.png");
}
.bg-awards {
  background-image: url("../assets/imgs/0016.png");
}
.bg-team {
  background-image: url("../assets/imgs/0020.png");
}
.bg-contact {
  background-image: url("../assets/imgs/0025.png");
}
.bg-con {
  background-image: url("../assets/imgs/0016.png");
}
.bgUrl {
  // background-image: url("../assets/logo_menu.gif");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 0;
  padding: 0;
}
</style>
