<template>
  <div id="app">
    <div class="flex_center nav_bar_phone">
      <nav class="content_navs flex_between">
        <div v-if="currentRoutePath=='/technicalInfo' ||currentRoutePath=='/newsInfo'">
          <div class="el-icon-arrow-left fontSize" @click="onClickBack"></div>
        </div>
       <div v-else>
        <div class="el-icon-s-unfold fontSize" v-if="!drawerMenu" @click="drawerMenu = true"></div>
        <div class="el-icon-s-fold fontSize" v-if="drawerMenu" @click="drawerMenu = false"></div>
       </div>
        <div class="flex-end content_">三京生物科技</div>
        <div style="color: transparent">1</div>
      </nav>
    </div>

    <!-- <div class="body_btn_foot">
      <div class="body_btn_f">@ 2024 版权所有杭州三京生物科技有限公司</div>
      <div class="body_btn_foots">
        网站备案号：
        <a
          class="btn_link"
          target="_blank"
          href="https://beian.miit.gov.cn/#/Integrated/index"
          >浙ICP备2024113011号</a
        >
      </div>
    </div> -->
    <el-drawer
      title="我是标题"
      size="50%"
      :visible.sync="drawerMenu"
      direction="ltr"
    >
      <nav class="content_navs">
        <div class="content_">
          <div class="dis_flex_center">
            <img class="logo_phone" :src="logo" alt="" @click="goIndex" />
            <!-- <div class="logo_title">三京生物</div> -->
          </div>
          <div class="dis_flex_center" style="margin-top: 80px;">
            <div class="menu_phone" @click="drawerMenu = false">
              <router-link to="/">首页</router-link>
            </div>
            <div class="menu_phone" @click="drawerMenu = false">
              <router-link to="/service">定制服务</router-link>
            </div>
            <div class="menu_phone" @click="drawerMenu = false">
              <router-link to="/technical">技术支持</router-link>
            </div>
            <div class="menu_phone" @click="drawerMenu = false">
              <router-link to="/news">新闻中心</router-link>
            </div>
            <div class="menu_phone" @click="drawerMenu = false">
              <router-link to="/about">关于三京</router-link>
            </div>
          </div>
        </div>
        <!-- <router-view /> -->
      </nav>
    </el-drawer>
  </div>
</template>
<script>
// import logo from "@/assets/imgs/logo.png";
import { listProductCategory } from "@/api/activity";
import { getCompany } from "@/api/customer/outCustomer";
export default {
  data() {
    return {
      // title: defaultSettings.title,
      // logo,
      title: "三京生物管理后台",
      isRouter: false,
      categoryOptions: [],
      shopList: "",
      drawerMenu: false,
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    currentRoutePath() {
      return this.$route.path;
    }
  },
  created() {
    const flag = this.IsPC();
    console.log("三京生物管理后台", flag);
    this.initCategoryData();
    this.getShopInfo();
  },
  methods: {
    handleClick(e) {
      const index = Number(e.index) + 1;
      this.$nextTick(() => {
        this.$refs[`tab-${index}`].scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        // this.$scrollTo(`#tab-${index}`)
      });
    },
    // 返回上一页吗
    onClickBack(){
      window.history.back(-1);
    },
    /**
     * 判断是否是pc设备
     */
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPod"];
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      if (window.screen.width >= 768) {
        flag = true;
      }
      return flag;
    },
    // 返回首页
    goIndex() {
      this.drawerMenu = false;
      this.$router.push("/");
    },
    // 获取商家信息
    async getShopInfo() {
      const res = await getCompany();
      this.shopList = res.result;
      console.log("商家信息", this.shopList);
    },
    initCategoryData() {
      // 查询类目接口
      listProductCategory()
        .then((res) => {
          console.log("查询类目", res);
          if (res.code === "200") {
            if (res.result.list && res.result.list.length > 0) {
              var arr = res.result.list;
              this.categoryOptions = arr;
              // this.query.categoryId = arr[0].categoryId;
              // this.fatchData();
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    navigator(item) {
      console.log("item", item);
      this.isRouter = !this.isRouter;
      if (this.isRouter) {
        this.$router.replace({
          name: "productCate",
          query: { productId: JSON.stringify(item) },
        });
      } else {
        this.$router.replace({
          name: "productCates",
          query: { productId: JSON.stringify(item) },
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
body {
  margin: 0;
  padding: 0;
}
.rounded {
  cursor: pointer;
}
.tooltip {
  display: none;
  // right: 30px;
  // top: 30px;
  position: absolute;
  // right: 30px;
  // top: 80px;
  background-color: #f7fbff;
  color: #000;
  padding: 10px 5px;
  border-radius: 5px;
  z-index: 10;
  .tooltip_name {
    margin: 10px;
    font-size: 14px;
    cursor: pointer;
  }
  .tooltip_name:hover {
    margin: 10px;
    font-size: 14px;
    color: #3558ff;
    cursor: pointer;
  }
}
.tooltips {
  width: 200px;
  text-align: left;
}
.color {
  font-size: 16px;
  color: #3558ff;
}
.color-weight {
  font-size: 14px;
  font-weight: 700;
  color: #000;
}
.hover-target:hover .tooltip {
  display: block;
  display: flex;
  position: absolute;
  background-color: #f7fbff;
  color: #000;
  // right: 30px;
  // top: 80px;
  padding: 10px 5px;
  border-radius: 5px;
  z-index: 10;
}
.content_navs {
  width: 95%;
  height: 60px;
  margin: 0 auto;
  .content_ {
    font-weight: 700;
    // width: 50%;
    // margin-left: 200px;
  }
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.logo_img {
  cursor: pointer;
}
nav {
  width: 30%;
  // padding: 30px;

  a {
    width: 15%;
    font-weight: bold;
    color: #020202;
    // margin: 0 10px;
    text-decoration: none;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-align: right;
    font-style: normal;
    &.router-link-exact-active {
      color: #3558ff;
      // padding-bottom: 20px;
      // text-decoration: none;
      text-decoration: dashed;
    }
  }
}

// @media only screen and (max-width: 1024px) {
//   .content_navs {
//     width: calc(100% - 120px);
//     // margin: 0;
//     .content_ {
//       width: 100%;
//       // margin-left: 20px;
//     }
//   }
// }
</style>
