import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/index.vue'),
    meta: { title: 'seehance design', }
   
  },
  {
    path: '/HomeView',
    name: 'HomeView',
    meta: { title: 'seehance design',name:"keywords", content:"seehance design" },
    component: HomeView
   
  },
  {
    path: '/PHILOSOPHY',
    name: 'PHILOSOPHY',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/PHILOSOPHY.vue'),
    meta: { title: 'seehance design', }
  },
  {
    path: '/WORKS',
    name: 'WORKS',
    component: () => import(/* webpackChunkName: "about" */ '../views/WORKS.vue'),
    meta: { title: 'seehance design', }
  },
  {
    path: '/WORKSVIEWS',
    name: 'WORKSVIEWS',
    component: () => import(/* webpackChunkName: "about" */ '../views/WORKSVIEWS.vue'),
    meta: { title: 'seehance design', }
  },
  {
    path: '/AWARDS',
    name: 'AWARDS',
    component: () => import(/* webpackChunkName: "about" */ '../views/AWARDS.vue'),
    meta: { title: 'seehance design', }
  },
  {
    path: '/AWARDSVIEWS',
    name: 'AWARDSVIEWS',
    component: () => import(/* webpackChunkName: "about" */ '../views/AWARDSVIEWS.vue'),
    meta: { title: 'seehance design', }
  },
  {
    path: '/TEAM',
    name: 'TEAM',
    component: () => import(/* webpackChunkName: "about" */ '../views/TEAM.vue'),
    meta: { title: 'seehance design', }
  },
  {
    path: '/CONTACT',
    name: 'CONTACT',
    component: () => import(/* webpackChunkName: "about" */ '../views/CONTACT.vue'),
    meta: { title: 'seehance design', }
  },
]

const router = new VueRouter({
  mode:'history',   //去除#号
  routes,
  scrollBehavior (to, from, savedPosition) {
    // 滚动到顶部
    return { x: 0, y: 0 }
  },
})
// 全局导航守卫  
router.beforeEach((to, from, next) => {  
  console.log('to',to);
  document.title = to.meta.title;  
  next();  
});  

export default router
