<template>
  <div class="body_btn_foot">
      <div class="dis_flex">
        <div  class="body_btn_f" :style="{color:color}">@ 2024 版权所有seehance design有限公司</div>
      <div class="body_btn_foots" :style="{color:color}">
        网站备案号：
        <a
          class="btn_link"
          target="_blank"
          href="https://beian.miit.gov.cn/#/Integrated/index"
          >京ICP备2024096944号</a
        >
      </div>
      </div>
    </div>
</template>

<script>
export default {
  props: {
   color:{
    type:String,
    default:"#000"
   }
  },
  data() {
    return {
      
    };
  },
  mounted() {
  },
  methods: {
 
  },
};
</script>

<style lang="scss" scoped>
.tab-bar {
  width: 100%;
  // padding: 0 15%;
  // height: 570px;
  // line-height: 48px;
  background: #151d33;
  background-repeat: no-repeat !important;
  background-size: 100% !important;
  display: flex;
  // align-items: center;
  justify-content: space-between;
  .tab-body {
    width: 85%;
    margin: 0 auto;
    display: flex;
    // align-items: center;
    justify-content: space-between;
  }
  .color {
    font-size: 24px;
    color: #848471;
  }
  .text-co {
    color: #70d0cf;
    font-size: 12px;
    margin-left: 7px;
    text-decoration: underline;
  }
  .tab-bar-title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    line-height: 22px;
    text-align: left;
    font-style: normal;
    margin-bottom: 20px;
  }
  .tab-bar-text {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    margin: 10px 0;
    font-size: 14px;
    color: #a1a5ad;
    line-height: 22px;
    text-align: left;
    font-style: normal;
  }
  .tab-bar-texts {
    cursor: pointer;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    margin: 10px 0;
    font-size: 14px;
    color: #a1a5ad;
    line-height: 22px;
    text-align: left;
    font-style: normal;
    text-decoration: none;
  }
  .tab-bar-texts:hover {
    cursor: pointer;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    margin: 10px 0;
    font-size: 14px;
    color: #6fc8ff;
    line-height: 22px;
    text-align: left;
    font-style: normal;
  }
  .tab-bar-item {
    color: #848471;
    // height: 210px;
    line-height: 30px;
    // padding: 16px 25px;
    padding: 15px 0;
    padding-top: 28px;
  }
  .tab-bar-items {
    color: #848471;
    // height: 210px;
    line-height: 30px;
    // padding: 16px 25px;
    text-align: center;
    padding: 15px;
    padding-top: 28px;
  }
  .tab-bar-img {
    width: 120px;
    height: 120px;
  }
  .icon_list {
    display: flex;
    align-items: center;
    margin-left: 15px;
    margin-bottom: 20px;
    // margin-top: 20px;
    .icon_line {
      width: 40px;
      height: 40px;
      border: 1px solid #848471;
      margin: 0 10px;
      background: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .icon {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .foot_tabbr {
    width: 100%;
    height: 60px;
    // line-height: 60px;
    // background: #1c1f24;
    text-align: center;
    .title {
      font-size: 13px;
      color: #6fc8ff;
      height: 35px;
      line-height: 56px;
    }
    .verison {
      font-size: 11px;
    }
  }
}
.popup-title {
  padding: 20px;
}
.tab-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 2px solid #f5f5f5;
  vertical-align: middle;
}
.popup-add {
  text-align: center;
  margin-bottom: 40px;
}
.popup-list {
  width: 100%;
  text-align: center;
  padding: 56px 0;
  .popup-center {
    width: 70%;
    background: #fff;
    text-align: center;
    border-radius: 20px;
    padding: 60px 0;
    margin: 0 auto;
  }
  .popup-iamge {
    width: 320px;
    height: 320px;
  }
  .center-text {
    margin-top: 40px;
  }
}
</style>
