<template>
  <div id="app">
    <!-- <component :is="currentComponent"></component> -->
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
    
  </div>
</template>
<script>
// import logo from "@/assets/imgs/logo.png";
import PcComponent from "@/components/PcComponent/index.vue";
import MobileComponent from "@/components/MobileComponent/index.vue";
export default {
  components: {
    MobileComponent,
    PcComponent
  },
  metaInfo: {
    title: '首页',
    meta: [
      { vmid: 'description', name: 'description', content: '三京生物' },
      { vmid: 'keywords', name:"keywords", content:"三京生物,杭州三京生物,抗体,抗体定制,蛋白定制,冷冻电镜,基因编辑,免疫学检测,兔多抗,多抗,纳米抗体" }
    ]
  },
  data() {
    return {
      // title: defaultSettings.title,
      // logo,
      title: "三京生物管理后台",
      isRouter: false,
      categoryOptions: [],
      shopList: "",
      currentComponent: 'PcComponent'
    };
  },
  created() {
    // if (this.isMobile) {
    //   this.currentComponent = 'MobileComponent';
    // }
  },
  methods: {
    handleClick(e) {
      const index = Number(e.index) + 1;
      this.$nextTick(() => {
        this.$refs[`tab-${index}`].scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        // this.$scrollTo(`#tab-${index}`)
      });
    },
    /**
     * 判断是否是pc设备
     */
    //  isMobile() {
    //   return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    // },
  },
};
</script>
<style lang="scss" scoped>
  /* 声明字体 */
  @font-face {
            font-family: 'AlibabaPuHuiTi-Medium';
            src: url('utils/fonts/AlibabaPuHuiTi-3-65-Medium.otf') format('truetype');
            font-weight: normal;
            font-style: normal;
        }

        @font-face {
            font-family: 'AlibabaPuHuiTi-Bold';
            src: url('utils/fonts/AlibabaPuHuiTi-3-75-SemiBold.otf') format('truetype');
            font-weight: bold;
            font-style: normal;
        }
#app{
  width: 100%;
  height: 100vh;
  font-family: 'AlibabaPuHuiTi-Medium', sans-serif !important;
}
 
body {
  margin: 0;
  padding: 0;
  font-family: 'AlibabaPuHuiTi-Regular', sans-serif !important;
}
/* 定义淡入淡出动画 */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

</style>
