<template>
  <div id="app">
    <div class="flex_center nav_bar ">
      <div class="flex_between nav_bars bgUrl">
        <img class="logo_img" :src="logo_small" width="120px" @click="goIndex" />
        <div class="nav_body">
          <div class="nav_item" v-for="(item, index) in navList" :key="item.id">
            <router-link class="nav_name" :to="item.pathName">
              {{ item.titleName }}
            </router-link>
          </div>
        </div>
        <img class="bottom_rihgt" :src="bottom_rihgt" width="120px" />
        <img class="lange" :src="lange" width="120px" />
      </div>
    </div>
  </div>
</template>
<script>
import logo_small from "@/assets/logo_small.png";
import bottom_rihgt from "@/assets/bottom_rihgt.png";
import lange from "@/assets/lange.png";
import { listProductCategory } from "@/api/activity";
import { getCompany } from "@/api/customer/outCustomer";
export default {
  data() {
    return {
      navList: [
        {
          id: 1,
          titleName: "PHILOSOPHY",
          pathName: "/PHILOSOPHY",
        },
        {
          id: 2,
          titleName: "WORKS",
          pathName: "WORKS",
        },
        {
          id: 3,
          titleName: "AWARDS",
          pathName: "",
        },
        {
          id: 4,
          titleName: "TEAM",
          pathName: "",
        },
        {
          id: 5,
          titleName: "CONTACT",
          pathName: "",
        },
        {
          id: 6,
          titleName: "4V TECH STUDIO",
          pathName: "",
        },
        {
          id: 1,
          titleName: "CC YE!",
          pathName: "",
        },
      ],
      logo_small,
      lange,
      bottom_rihgt,
      isRouter: false,
    };
  },
  created() {
    const flag = this.IsPC();

    // this.initCategoryData();
    // this.getShopInfo();
  },
  methods: {
    navigator(item, index) {
      this.$router.push({
        name: item.pathName,
        // query: { categoryId: item.categoryId },
      });
    },
    /**
     * 判断是否是pc设备
     */
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPod"];
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      if (window.screen.width >= 768) {
        flag = true;
      }
      return flag;
    },
    // 返回首页
    goIndex() {
      this.$router.push("/");
    },
  },
};
</script>
<style lang="scss" scoped>
body {
  margin: 0;
  padding: 0;
}
.bgUrl {
  background-image: url("../../assets//logo_menu.gif");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 0;
  padding: 0;
}
</style>
