import { axios } from '@/utils/request'
// import excelRequest from '@/utils/excelRequest'
export function getAppletPageByType(data) {
    return axios({
        url: '/website/getAppletPageByType',
        method: 'post',
        data
    })
}
// 稀罕官网查询首页信息
export function getContent(data) {
    return axios({
        url: '/website/getContent',
        method: 'post',
        data
    })
}
// 幂等
export function getToken(data) {
    return axios({
      url: '/idempotent/getToken',
      method: 'post',
      data
    })
  }
//分页查询产品
export function listPageProduct(data) {
    return axios({
        url: '/website/listPageProduct',
        method: 'post',
        data
    })
}
//根据商品id查看商品详情
export function getProductById(data) {
    return axios({
        url: '/website/getProductById',
        method: 'post',
        data
    })
}
// 分页查询证书
export function listPageBook(data) {
    return axios({
        url: '/website/listPageBook',
        method: 'post',
        data
    })
}
// 根据证书id，查询证书的基本信息
export function getBookById(data) {
    return axios({
        url: '/website/getBookById',
        method: 'post',
        data
    })
}
// 稀罕官网查询团队信息
export function getTeamInfo(data) {
    return axios({
        url: '/website/getTeamInfo',
        method: 'post',
        data
    })
}

