import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import router from './router'
import store from './store'
import '@/styles/index.scss' // global css
import '@/styles/mobile.scss' // global css

import BottomInfo from '@/components/bottom-info/bottom-info'
Vue.component('BottomInfo', BottomInfo)

import VueAMap from 'vue-amap'
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.use(VueAMap)
VueAMap.initAMapApiLoader({
  key: '1022a5be6feaf7b0dc1a9a96916f8093',
  plugin: [
    'AMap.Autocomplete',
    'AMap.PlaceSearch',
    'AMap.Geocoder',
    'AMap.Scale',
    'Geocoder',
    'AMap.OverView',
    'AMap.ToolBar',
    'AMap.MapType',
    'AMap.PolyEditor',
    'AMap.CircleEditor',
    'AMap.DistrictSearch'
  ],
  v: '1.4.15'
})
// 创建一个全局混入
Vue.mixin({
  data() {
    return {
      isMobile: false
    };
  },
  created() {
    this.checkDevice();
  },
  methods: {
    checkDevice() {
      this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
  }
});
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
